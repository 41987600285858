import Vue from 'vue';

import objectFitImages from 'object-fit-images';

import 'functions/pv';
import TagExpand from 'modules/TagExpand';
import prefetch from 'serviceworker/prefetch';
import addHistory from 'functions/addHistory';
import registerServiceWorker from 'functions/registerServiceWorker';
import welcomeToNewsConsole from 'functions/welcomeToNewsConsole';
import { bindScrollToTop } from 'functions/bindScroll';

import store from 'stores/root';
import { UPDATE_IS_MOBILE } from 'stores/mutationTypes';
import ArticleHistories from 'components/ArticleHistories.vue';

registerServiceWorker();

const main = () => {
  // eslint-disable-next-line no-new
  new Vue({
    el: '#js-article-histories',
    store,
    components: {
      ArticleHistories,
    },
    render: h => h(ArticleHistories),
  });

  store.commit(UPDATE_IS_MOBILE, true);

  objectFitImages();
  new TagExpand('.c-tags', '.js-more'); // eslint-disable-line no-new
  addHistory();
  bindScrollToTop('.js-pagetop');
  welcomeToNewsConsole();
  prefetch();
};

document.addEventListener('DOMContentLoaded', main);
